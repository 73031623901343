@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}

.select-bay::-webkit-scrollbar{
    display: none;
  }

.select-bay {
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
.promo {
	border-width: 1px;
	border-color: black;
	color: black;
}

.baloon-content {
	background-color: #D2233C;
	color: white;
	font-size: 12px;
	font-weight: 500;
	font-family: 'Inter';
	height: 30px;
	min-width: 140px;
	padding: 5px;
	border-radius: 8px;
	transform: rotate(180deg);
}

.ymaps-2-1-79-balloon {
	margin-left: -23px !important;
	box-shadow: none !important;
	transform: rotate(180deg) !important;
  }

.ymaps-2-1-79-balloon__content {
	padding: 0px !important;
	background: transparent !important;
	margin: 0 !important;
	border: none !important;
	transform: rotate(360deg) !important;
  }
  
  .ymaps-2-1-79-balloon__layout {
	background: transparent !important;
	border: none !important;
  }

  .ymaps-2-1-79-balloon__tail::after {
	background-color: #D2233C !important;
  }